.spinner-border {
    --falcon-spinner-width: 10rem !important;
    --falcon-spinner-height: 10rem !important;
}

.navbar-vertical .navbar-nav .nav-item .nav-link {
    cursor: pointer;
    font-size: 10px;
}

.form-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 75%;
    color: #e63757;
}

.details-form label {
    padding-left: 0px !important;
}

.error-message {
    color: #e63757;
    padding-left: 0px !important;
    font-size: 15px;
}

.table-striped tr:hover {
    cursor: pointer;
}

.row-selected,
.table-striped tbody tr:hover {
    cursor: pointer;
    background-color: rgba(237, 242, 249, 1);
}

.table-striped>tbody>tr:nth-of-type(2n)>* {
    background-color: var(--falcon-table-bg) !important;
}

.navbar {
    padding: 0 10px 0 0 !important;
}

.navbar-top {
    min-height: unset !important;
}

.navbar-nav {
    --falcon-nav-link-padding-y: 0.3rem !important;
    margin-bottom: -5px !important;
}

.toggle-logo {
    padding-bottom: 5px;
}

.navbar-collapse {
    max-height: 1260px;
    overflow: scroll;
}

.navbar-top {
    margin-left: unset !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #88c431;
    color: white;
}

.nav-tabs .nav-link,
.nav-tabs .nav-link {
    isolation: isolate;
    border-color: #6c757d47 !important;
    margin-left: 5px;
}

.form-label,
input::placeholder,
.form-control,
.form-select,
.nav-tabs .nav-link,
.error-message {
    font-size: 0.8rem !important;
}

.table-striped {
    font-size: 0.733333rem !important;
}

.table-striped>tbody>tr:nth-of-type(2n)>* {
    color: var(--bs-body-color) !important;
}

#TableFooterPanel .card-body {
    padding: 0 15px 15px 15px;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

#TableSearchPanelCard .card-body {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

#TableFooterPanel,
#ContactDetailsTable {
    margin-bottom: unset !important;
}

.list-card .card-body,
.list-tab-card .card-body {
    padding: 10px 10px 10px 10px;
}

.no-pad .card-body {
    padding: 0px !important;
}

.no-pb,
.no-pb .card-body {
    padding-top: 5px;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.no-padding {
    padding: 0px 0px 0px 0px !important;
}

.w-40 {
    width: 40%;
}

.custom-bg-200 {
    background-color: #5e6e8221;
}

.table-bordered>:not(caption)>* {
    border-width: 3px 0 !important;
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 3px !important;
}

.tab-page-table>:not(caption)>*>* {
    padding: 0.4rem !important;
}

.tab-page-table td:first-child {
    text-align: center;
    width: 40px;
}

.p3px {
    padding: 3px !important;
}

.p5px {
    padding: 5px !important;
}

.card-header {
    padding: 0.75rem 1rem !important;
}

.passport-sized-photo {
    height: 135px !important;
    width: 125px !important;
}

.logo-photo {
    height: 125px !important;
    width: 125px !important;
}

.space-between-row {
    display: flex;
    justify-content: space-between;
}

.flex-col {
    flex: 1;
}

.row-content-pt5px .card-body {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.no-right-pad {
    padding-right: 0px !important;
}

/* .col-left-pad{
    padding-left: 5px !important;
} */

.vendor-card-body {
    padding: 0 !important;
    margin: 5px !important;
}

.no-left-pad {
    padding-left: 5px !important;
}

.margin-right-11 {
    margin-right: -11px !important;
}

.margin-left-11 {
    margin-left: -11px !important;
    padding-right: 0px !important;

}

.custom-card-scroll {
    max-height: 112px;
    min-height: 111px;
    overflow-y: auto;
}

.custom-card-body-scroll {
    overflow-x: hidden;
}

.card-box-padding {
    padding: 15px 20px 21px 20px !important;
}

.vebdor-card-item {
    padding: 9px 5px 4px 16px !important;
}

.no-padding-mar{
    padding: 0px !important;
    margin: 0px !important;
}

.custom-card-invoice-scroll {
    max-height: 111px;
    min-height: 111px;
    overflow-y: auto;
}

.vebdor-card-invoice-item {

    padding: 10px 5px 4px 16px !important;
}

.farmer-card-item {
    padding: 0px 0px 0px 0px !important;
}

.tm-symbol {
    font-weight: normal; /* Ensures the TM symbol is not bold */
    font-size: 1.0em;    /* Adjust the size to your preference */
    vertical-align: super; /* Adjusts the position to align with the text */
  }
  