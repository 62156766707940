@media (min-width:320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
}

@media (min-width:481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 576px) {
    .content {
        padding-bottom: 2.2875rem !important;
    }
}

@media (min-width:641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}

@media (min-width: 768px) {
    .container {
        max-width: 1024px;
    }
}

@media (min-width:961px) {}

@media (min-width:1025px) {
    /* .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 12.825rem !important;
    } */

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 3.1rem !important;
    }

    .footer-credits {
        margin-left: 230px !important;
    }

    .tab-page-tab {
        max-height: 465px;
        overflow-y: scroll;
    }

    .list-card {
        min-height: 560px;
        max-height: 560px;
        overflow-y: scroll;
    }

    .max-five-rows {
        max-height: 291px;
        overflow-y: scroll;
    }

    .nav-tabs .nav-link,
    .form-label,
    .form-select,
    .form-control,
    input::placeholder,
    .table-striped {
        font-size: 10px !important;
    }

    .btn {
        font-size: 12px !important;
    }

    .h4,
    h4 {
        font-size: 1rem !important;
    }

    h5,
    h6 {
        font-size: .8rem !important;
    }

    tbody .form-control,
    tbody .form-select {
        min-width: 120px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1600px !important;
    }

    .max-five-rows {
        max-height: 295px;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        min-height: 155px;
        overflow-y: scroll;
    }

    .cp-table-card {
        min-height: 155px;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: 155px;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 320px;
        overflow-y: scroll;
    }

    .full-tab-card-body {
        min-height: 376px;
    }

    .full-tab-page-card-body {
        min-height: 470px;
    }

    .half-screen-card {
        min-height: 225px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 365px;
    }
}

@media (min-width:1280px) {
    .container {
        max-width: 1600px !important;
    }

    .footer-credits {
        margin-left: 250px !important;
    }

    /* .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 13.625rem !important;
    } */

    .navbar-vertical-collapsed .navbar-vertical.navbar-expand-xl+.content {
        margin-left: 3.1rem !important;
    }

    .tab-page-tab {
        max-height: 475px;
        overflow-y: scroll;
    }

    .list-card {
        min-height: 500px;
        max-height: 500px;
        overflow-y: scroll;
    }

    .tab-page-table-card {
        max-height: 170px;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        max-height: 162px;
        min-height: 162px;
        overflow-y: scroll;
    }

    .cp-table-card {
        max-height: 162px;
        min-height: 162px;
        overflow-y: scroll;
    }

    .cp-table-card-responsive {
        max-height: 162px;
        min-height: 300px !important;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: 157px;
        max-height: 157px;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 320px;
        overflow-y: scroll;
    }

    h5 {
        font-size: 0.8rem !important;
    }

    .micro-form label {
        padding-left: 0px !important;
        padding-right: 0px !important;
        font-size: 10px !important;
    }

    .micro-form .form-control,
    .micro-form input::placeholder,
    .micro-form .form-select,
    .micro-form .error-message {
        font-size: 10px !important;
    }

    .max-five-rows {
        max-height: 290px;
        overflow-y: scroll;
    }

    .many-column-table .form-control {
        width: 105px !important;
    }

    .full-tab-card-body {
        min-height: 376px;
    }

    .farmer-card-row1 {
        min-height: 237px;
    }

    .farmer-card-row1 {
        min-height: 90% !important;
    }

    .farmer-card-row2 {
        min-height: 267px;
    }

    .full-tab-page-card-body {
        min-height: 462px;
    }

    .half-screen-card {
        min-height: 225px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 365px;
    }

    .select {
        width: 110px !important;
    }

    /* .enlargeText{
        width: 50px !important;
    } */
}

@media (min-width:1400px) {
    .tab-page-tab {
        max-height: 545px;
        overflow-y: scroll;
    }

    .list-card {
        min-height: 600px;
        max-height: 600px;
        overflow-y: scroll;
    }

    .tab-page-table-card {
        max-height: 215px;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        min-height: 196px;
        max-height: 196px;
        overflow-y: scroll;
    }

    .cp-table-card {
        min-height: 233px;
        max-height: 233px;
        overflow-y: scroll;
    }

    .cp-table-card-responsive {
        min-height: 233px;
        max-height: 413px !important;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: 226px;
        max-height: 226px;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 340px;
        overflow-y: scroll;
    }

    .max-five-rows {
        max-height: 294px;
        overflow-y: scroll;
    }

    .many-column-table .form-control {
        width: 135px !important;
    }

    .full-tab-card-body {
        min-height: 444px;
    }

    .full-tab-page-card-body {
        min-height: 525px;
    }

    .half-screen-card {
        min-height: 260px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 435px;
    }

    .select {
        width: 110px !important;
    }

    .enlargeText {
        width: 50px !important;
    }
}

@media (min-width:1600px) {
    .tab-page-tab {
        min-height: 627px;
        max-height: 627px;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        min-height: 237px;
        max-height: 237px;
        overflow-y: scroll;
    }

    .cp-table-card {
        min-height: 310px;
        overflow-y: scroll;
    }

    .cp-table-card-responsive {
        min-height: 233px;
        max-height: 413px !important;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: 323px;
        max-height: 323px;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 360px;
        overflow-y: scroll;
    }

    .full-tab-card-body {
        min-height: 527px;
    }

    .list-card {
        min-height: 612px;
        max-height: 612px;
        overflow-y: scroll;
    }

    .full-tab-page-card-body {
        min-height: 610px;
    }

    .half-screen-card {
        min-height: 286px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 485px;
    }
}
@media (min-width:1400px) {

    .cp-table-card-responsive {
        min-height: 233px;
        min-height: 400px !important;
        overflow-y: scroll;
    }

}

@media (min-width:1680px) {
    .container {
        max-width: 1700px !important;
    }

    .footer-credits {
        margin-left: 460px !important;
    }

    .tab-page-tab {
        min-height: 676px;
        max-height: 676px;
        overflow-y: scroll;
    }

    .list-card {
        min-height: 782px;
        max-height: 782px;
        overflow-y: scroll;
    }

    .tab-page-table-card {
        max-height: 340px;
        overflow-y: scroll;
    }

    .tab-page-button-table-card {
        min-height: 262px;
        max-height: 262px;
        overflow-y: scroll;
    }

    .cp-table-card {
        min-height: 360px;
        max-height: 360px;
        overflow-y: scroll;
    }
    
    .cp-table-card-responsive{
        min-height: 480px !important;
        max-height: 360px !important;
        overflow-y: scroll;
    }

    .mr-table-card {
        min-height: 373px;
        max-height: 373px;
        overflow-y: scroll;
    }

    .big-card-table {
        min-height: 400px;
        overflow-y: scroll;
    }

    .max-five-rows {
        max-height: 295px;
        overflow-y: scroll;
    }

    .many-column-table .form-control {
        width: 160px !important;
    }

    .full-tab-card-body {
        min-height: 578px;
    }

    .full-tab-page-card-body {
        min-height: 660px;
    }

    .half-screen-card {
        min-height: 329px;
        overflow-y: scroll;
    }

    .big-card-body {
        min-height: 572px;
    }
}

@media print {    
    .hide-on-print {
        display: none !important;
    }
}

.card-sidebar {
    background-color: #edf2f9;
}